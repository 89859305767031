<template>
  <div class="trade-statistics">
    <div class="trade-statistics__header">
      <h3 class="trade-statistics__title">
        Статистика за {{ period }}
      </h3>
    </div>
    <div class="trade-statistics__body">
      <ul class="trade-statistics__list">
        <li
          class="trade-statistics__item"
          v-for="item in statistics"
          :key="item"
        >
          <VTextBlock
            :subtitle="item.label"
            :description="item.text"
          />
        </li>
      </ul>
    </div>
    <div v-if="price" class="trade-statistics__footer">
      <h4 class="trade-statistics__subtitle">
        Всего ТП на сумму:
      </h4>
      <span class="trade-statistics__price">
        {{ formatCurrency(price) }} руб.
      </span>
    </div>
  </div>
</template>

<script>
import VTextBlock from '@/components/textblock/VTextBlock'
import { formatCurrency } from '@/utils/format/number'

export default {
  props: {
    period: {
      type: String,
      required: true
    },
    statistics: {
      type: Object,
      required: true
    },
    price: {
      type: Number,
      required: false,
      default: 0
    }
  },

  setup () {
    return {
      formatCurrency
    }
  },

  components: {
    VTextBlock
  }
}
</script>

<style lang="scss" scoped>

.trade-statistics {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    padding: 20px 30px;
    background: #FFED00;
  }

  &__body,
  &__footer {
    background: #F3F5F3;
  }

  &__body {
    padding: 30px;
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 25px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__title,
  &__price {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  &__count {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
