<template>
  <VDateInput
    class="mb-40"
    is-range
    placeholder="Выберете период"
    v-model="dateRange"
  />
  <TradeStatisticsWork
    v-if="dateRange"
    :period="period"
    :statistics="statistictTrade"
    :price="totalPrice"
  />
</template>

<script>
import TradeStatisticsWork from '@/components/views/Organizer/VOrganizerTradeStatisticsWork'
import VDateInput from '@/components/ui/form/VDateInput'
import { computed, ref } from 'vue'
import { formatDate } from '@/utils/formatDate'

export default {
  setup () {
    // заглушки
    const totalPrice = 20000

    const statistictTrade = [
      { name: 'Всего', count: 30 },
      { name: 'Общее количество завершенных ТП', count: 11 },
      { name: 'Победитель определен', count: 7 },
      { name: 'Не состоявшиеся ТП', count: 5 },
      { name: 'Активных ТП:', count: 20 },
      { name: 'Не опубликованные', count: 3 }
    ]

    const dateRange = ref(null)

    const dateOne = ref(new Date(2022, 2, 20))

    const period = computed(() => {
      return formatDate(dateRange.value.start) + ' - ' + formatDate(dateRange.value.end)
    })

    const formatDateOne = computed(() => {
      return formatDate(dateOne.value)
    })

    return {
      statistictTrade,
      totalPrice,
      dateRange,
      dateOne,
      period,
      formatDateOne
    }
  },
  components: {
    TradeStatisticsWork,
    VDateInput
  }
}
</script>

<style scoped>

</style>
